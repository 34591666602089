

























import { useApi } from '@/composables/useApi';
import { useLoader } from '@/composables/useLoader';
import { defineComponent, PropType } from '@/plugins/composition';
import { PaymentMethod } from '@stripe/stripe-js';

export default defineComponent({
  name: 'PaymentMethodCard',
  props: {
    method: {
      type: Object as PropType<PaymentMethod>,
      required: true,
    },
    isDefault: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { loading, withLoader } = useLoader();
    const { post, $delete } = useApi();

    const destroy = async () =>
      withLoader(async () => {
        const response = await $delete(`/payment-methods/${props.method.id}`);
        if (response) {
          emit('deleted');
        }
      });

    const makeDefault = async () =>
      withLoader(async () => {
        const response = await post('/payment-methods/default', {
          id: props.method.id,
        });

        if (response) {
          emit('defaultChanged');
        }
      });

    return {
      destroy,
      makeDefault,
      loading,
    };
  },
});
