















































































import { useApi } from '@/composables/useApi';
import { useAuthStore } from '@/composables/useAuthStore';
import { PendingReservation } from '@/composables/useBookedResources/types';
import { getPendingReservations } from '@/composables/useBookedResources/useBookedReservations';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { defineComponent, onMounted, ref } from '@/plugins/composition';
import PendingReservationListItem1 from './components/PendingReservationListItem1.vue';
import { formatPrice } from '@/utils/number';
import { routeNames } from '@/router/routeNames';
import BaseButton from '@/components/base/BaseButton.vue';
import { useLoader } from '@/composables/useLoader';
import { usePayments } from '@/composables/usePayments';
import { PaymentMethod } from '@stripe/stripe-js';
import { useRouter } from '@/router/useRouter';
import { useConfirmation } from '@/composables/useConfirmation';
import PaymentMethods from '@/views/ProfilePage/components/PaymentMethods.vue';

type Payment = {
  reference: string;
  total: number;
};

export default defineComponent({
  components: { MemberPage, PendingReservationListItem1, BaseButton, PaymentMethods },
  name: 'CheckoutPage',
  setup() {
    const reservations = ref<PendingReservation[]>([]);
    const total = ref(0);
    const defaultPaymentMethod = ref<PaymentMethod | null>(null);
    const payment = ref<Payment | null>(null);
    const dialog = ref(false);
    const paymentError = ref<string | null>(null);
    const addingPaymentMethod = ref(false);

    const { get, post } = useApi();
    const { user } = useAuthStore();
    const { loading, withLoader } = useLoader({ initial: true });

    const { getDefaultPaymentMethod } = usePayments();
    const { router } = useRouter();
    const { confirmation } = useConfirmation();
    const fetchTotal = async () =>
      withLoader(async () => {
        const response = await get<{ total: number }>(`/users/${user.value.uuid}/checkout/details`);
        defaultPaymentMethod.value = await getDefaultPaymentMethod();

        total.value = response.total;
      });

    const fetchReservations = async () =>
      withLoader(async () => {
        reservations.value = await getPendingReservations();
        localStorage.setItem('numberBookingItems', reservations.value.length.toString())
        window.dispatchEvent(new CustomEvent('number-booking-changed', {
          detail: {
            storage: localStorage.getItem('numberBookingItems')
          }
        }));
      });

    const pay = async () =>
      withLoader(async () => {
        await user.value
          .makePayment()
          .catch((error) => {
            if (error.response.data.redirect) {
              window.location.href = error.response.data.redirect;
            } else {
              paymentError.value = error.response.data.message;
            }
          })
          .then((res) => {
            if (res.total) {
              dialog.value = true;
              payment.value = res;
            }
          });
      });

    const goToBookings = () => {
      router.push({ name: routeNames.my.bookings });
    };

    const refresh = async () => {
      await fetchReservations();
      await fetchTotal();
    };

    onMounted(async () => {
      await fetchReservations();
      await fetchTotal();
    });

    const payByInvoice = async () => {
      const text =
        'To request a booking and to pay by invoice, you will need to delete the booking you have just made and contact <a href="mailto:bookings@brandx.org.au">bookings@brandx.org.au</a> with your booking request (including location, date, time, billing name and address). <br><br> Please allow 48 hours for Brand X to confirm your request, which will be subject to availability at the time we answer your email.';
      const confirm = await confirmation(text, { confirmTitle: 'Are you sure?', confirmText: 'Delete Bookings' });

      if (confirm) {
        withLoader(async () => {
          const response = await post('empty-cart', {});
          if (response) {
            router.push({ name: routeNames.my.bookings });
          }
        });
      }
    };

    const methodAdded = async () => {
      await fetchTotal();
      addingPaymentMethod.value = false;
    };

    const addPaymentMethod = () => {
      addingPaymentMethod.value = true;
    };

    return {
      reservations,
      total,
      routeNames,
      formatPrice,
      loading,
      pay,
      defaultPaymentMethod,
      payment,
      dialog,
      goToBookings,
      refresh,
      paymentError,
      payByInvoice,
      user,
      addingPaymentMethod,
      addPaymentMethod,
      methodAdded,
    };
  },
});
