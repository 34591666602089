














import { defineComponent } from '@/plugins/composition';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

export default defineComponent({
  name: 'MemberPage',
  props: {
    title: {
      type: String,
      default: '',
    },
    heading: {
      type: String,
      default: null,
      required: false,
    },
    titleClass: {
      type: String,
      default: '',
      required: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  setup() {
    const { md } = useBreakpoints(breakpointsTailwind);

    return {
      md,
    };
  },
});
